import axios from 'axios'
import mapLibre, {GeoJSONFeature, Map, MapMouseEvent, Marker} from 'maplibre-gl'
import { AppWindow, roundCoordinate, roundNumberToNPlaces } from './utils/helpers'


const initialCoordinates = [41.9027835, 12.4963655].reverse()

const isPointView = window.location.pathname.indexOf("point-view")> -1;

const map = new Map({
    container: "main-window",
    style: "https://api.maptiler.com/maps/8e43e9d5-f74c-481b-aed3-b97b594d3332/style.json?key=ALHXOTvIRWUocmyJ3VBN",
    zoom: 13.5,
    center: [initialCoordinates[0], initialCoordinates[1]],
})




function generateGeoJson(points:any[]){
    
    const features = points.map(point => {
    
        const size = point.pointScale * 3;
    
    
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [...point.location.coordinates]
            },
            properties: {
                iconSize: [size, size],
                name: point.name,
                category: point.pointType,
                slug: point.slug
            }
        }})
    const geoJson = {
          type: "FeatureCollection",
          features,
    }
    
    return geoJson
}


async function fetchData(){
    
    console.log(window.location)
    
    try {
        
        let reqPath = "/api/points";
        if(isPointView){
            const urlParams = new URLSearchParams(window.location.search);
            const slug = urlParams.get('name');
            
            reqPath = "/api/points?where[slug][equals]="+slug;
        }
        
        const req = await axios.get(reqPath);
        const points = req.data.docs
        const geoJson = generateGeoJson(points)

        const data = {
            geoJson,
            points
        }
        
        return [data, false] as [data, false]
    } catch (error) {
        return [{}, true] as [IPoint[], true]
    }
}



async function main(){
    const [data, err] = await fetchData()
    
    map.setCenter([...data.points[0].location.coordinates]);
    if(isPointView) map.setZoom(15);
    
    document.addEventListener("changeCategoria", (e)=> {
        const {selected} = e.detail
        
        if(selected){
            
            const pointsFiltered = data.points.filter(point => point.pointType == selected)
        
            const newData = {
                geoJson: generateGeoJson(pointsFiltered),
                points: pointsFiltered
            }
        
            const evt = new CustomEvent("pointsUpdated", {
                detail: {
                    points: pointsFiltered
                }
            })
            
            
            document.dispatchEvent(evt);
            
            drawMarkers(newData)
            
            
        } else {
            
            const evt = new CustomEvent("pointsUpdated", {
                detail: {
                    points: data.points
                }
            })
        
        
            document.dispatchEvent(evt);
            
            drawMarkers(data)
        }
        
    })

    
    
    
    if(err){
        alert("unable to fetch data")
    } else {
        drawMarkers(data)
    }
}


function handleClick(event:MapMouseEvent, data:IPoint[]){
    event.preventDefault()
    const point = data.find(p => {
        
        const roundEventLat = roundCoordinate(event.lngLat.lat)
        const roundEventLng = roundCoordinate(event.lngLat.lng)
        const roundPointLat = roundCoordinate(p.location.coordinates[1]);
        const roundPointLng = roundCoordinate(p.location.coordinates[0])
        return roundEventLat === roundPointLat && roundEventLng === roundPointLng
    })    
    
    if(point != undefined){
        
        const sidebar = window.sidebar
        sidebar.updateTitle(point.name)
        const content = document.createElement("div")
        content.innerText = "porcodio"
        sidebar.updateBody(content)
        
    }
    
}


let markers:Marker[] = [];

function drawMarkers(data:any){
    
    for(let i = 0; i < markers.length; i++){
        
        const marker = markers[i];
        marker.remove()
        
        
    }
    
    markers = [];
    
    const {features} = data.geoJson;
    
    features.forEach(function(marker: any){
        
        console.log(marker)
        
        const el = document.createElement("a");
        const url = `/point-view?name=${marker.properties.slug}`
        el.href = url

        el.addEventListener("click", (e) => {
            const panels = document.getElementsByClassName("transition-layer")
            window.animateLink(panels, e)
        })
        
        const radius = marker.properties.category === "arte" ? "100px" : "0px";
        
        
        el.style.background = "var(--primary-color)"
        el.classList.add("market")
        el.style.width = marker.properties.iconSize[0] + "px"
        el.style.height = marker.properties.iconSize[1] + "px"
        el.style.borderRadius = radius
        
        const mark = new Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map)

        
        markers.push(mark)
     
    });
    
    
    // map.on("mousedown", "marker", event => handleClick(event, data.points) )
    // map.on("mouseover", "point", event => {})
}


map.on("load", function(){
    main()
})