export function roundNumberToNPlaces(num:number, places:number){
  return (
    Number(
      Math.round(
        parseFloat(`${num}e-${places}`
      ) + parseFloat(`e-${places}`)
    )
  ))
}


export function roundCoordinate(n:number, precision=6){
  const num = n.toPrecision(precision)
  const str = String(num)
  return parseFloat(str.slice(0, -1))
}




export class AppWindow{
  width: number
  height: number
  title: "prova"
  visible: boolean
  container: HTMLDivElement
  titleBar: HTMLDivElement
  point: IPoint
  
  static windowContainer = document.querySelector(".desktop-windows")
  static windows:AppWindow[] = []
  
  constructor(data,visible=false, width=400, height=600){
    this.width = width;
    this.height = height;
    this.visible = visible;
    this.title = data.title
    this.initWindow()
    this.point = data.data
    console.log("data, ", data.data)
  }
  
  private initWindow(){
    const container = document.createElement("div")
    container.style.width = `${this.width}px`;
    container.style.height = `${this.height}px`;
    container.style.top = `${Math.random() * 100}px` 
    container.style.left = `${Math.random() * 500}px`
    
    container.classList.add("window")
    if(this.visible){
      container.classList.add("window-active", "window-visible")
    }    
    
    this.container = container
    this.attachTitleBar()
    
    container.addEventListener("mouseup", (evt) => this.focusWindow(evt))
    AppWindow.windowContainer?.appendChild(container)
    AppWindow.windows.unshift(this)
  }
  
  private attachToContainer(el:HTMLElement){
    this.container.appendChild(el)
  }
  
  attachTitleBar(){
    const titleBarContainer = document.createElement("div")
    titleBarContainer.classList.add("window-titlebar")
    
    const titleBarTitle = document.createElement("div")
    titleBarTitle.classList.add("titlebar-title")
    titleBarTitle.innerText = this.title
    
    const titleBarIcons = document.createElement("div")
    titleBarIcons.classList.add("titlebar-icons")
    
    const fullScreenIcon = document.createElement("button")
    fullScreenIcon.classList.add("titlebar-icon", "fullscreen-icon")
    fullScreenIcon.innerText = "F"
    fullScreenIcon.addEventListener("click", (evt) => this.toggleFullScreen(evt))
    
    const closeIcon = document.createElement("button")
    closeIcon.classList.add("titlebar-icon", "close-icon")
    closeIcon.innerText = "C"
    closeIcon.addEventListener("click", evt => this.closeWindow(evt))
    
    
    titleBarIcons.appendChild(fullScreenIcon)
    titleBarIcons.appendChild(closeIcon)
    
    this.dragWindow(this.container, titleBarContainer)
    
    
    
    const windowContent = document.createElement("window-body-container")
    windowContent.innerText = "this.point.coordinates.latitude"
    windowContent.style.flex = "1"
    
    
    const windowFooter = document.createElement("div")
    windowFooter.classList.add("window-footer")
    windowFooter.innerText = "ciao"
    
    
    
    titleBarContainer.appendChild(titleBarTitle)
    titleBarContainer.appendChild(titleBarIcons)
    
    this.attachToContainer(titleBarContainer)
    this.attachToContainer(windowContent)
    this.attachToContainer(windowFooter)
    
    this.titleBar = titleBarContainer;
  }
  
  
  
  
  toggleFullScreen(evt){
    console.log(this.container.classList)    
    if(!this.container.classList.contains("window-fullscreen")){
      this.container.classList.add("window-fullscreen")
    } else {
      this.container.classList.remove("window-fullscreen")
    }
  }
  
  
  closeWindow(){
    console.log("ciao")
    this.container.remove()
  }
  
  
  focusWindow(evt:MouseEvent){
    evt.preventDefault()
    const windows = document.getElementsByClassName("window")
    for(let w of windows){
      if(w.classList.contains("window-active")){
        w.classList.remove("window-active")
      }
    }
    
    
    this.container.classList.add("window-active")
  }
  
  
  dragWindow(element:HTMLDivElement, titleBar: HTMLDivElement){
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    titleBar.onmousedown = drag;
    
    
    function drag(e:MouseEvent){
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragEvent;
      document.onmousemove = onMouseDrag
    }
    
    function onMouseDrag(e:MouseEvent){
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      
      element.style.top = (element.offsetTop - pos2) + "px"
      element.style.left = (element.offsetLeft - pos1) + "px"
    }
    
    function closeDragEvent(){
      document.onmouseup = null;
      document.onmousemove = null;
    }
    
    
  }
      
}